import React from 'react';

import IconFactory from '../../../icons/factory-builder';
import ActionRow from '../../../action-row';

const namespace = 'ui-pdp-action';

const buildActionRow = (label, url, htmlTarget, icon, onClickHandler, style_blocked) => {
  const figure = IconFactory(icon);
  return (
    <ActionRow
      className={`${namespace}__action`}
      label={label.text}
      url={url}
      htmlTarget={htmlTarget}
      style_blocked={style_blocked}
      modifier="secondary"
      figure={figure}
      onClick={onClickHandler}
    />
  );
};

export default buildActionRow;
